import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Textarea from '@mui/joy/Textarea';
import { useState } from "react";
import { LoadingButton } from "@mui/lab";


const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    setIsLoading (true)
    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      }) 
      .then(function(res)
        {
          if (res.status == 200)
          {alert("Message Sent!");}
          else
          {alert("Message Failed! Error Code: " + res.status);}
        }
      ).finally(()=>{setIsLoading (false); form.current.reset()})
  };

  return (
    <React.Fragment>
      <h1 className=' text-center mt-6 text-4xl font-bold '>HMR.CO.IN</h1>
      <p className='text-center text-red-600 mt-8 text-2xl'>This domain is <b>AVAILABLE FOR SALE!</b> For further inquiry please <b>CONTACT US</b>, we'll get back to you <b>SOON</b>...</p>
    <form ref={form} onSubmit={sendEmail} className='  flex flex-col w-[60%] md:w-[30%] py-24 gap-4 mx-auto'>
      <FormControl>
      <TextField required= 'true' label="Name" variant="outlined" name='from_name' disabled={isLoading}/>
      </FormControl>
      <FormControl>
      <TextField required= 'true' label="Email Address" type='email' variant="outlined" name='from_mail' disabled={isLoading}/>
      </FormControl>
      <FormControl>
      <Textarea required= 'true' placeholder="Type your message here" name="message"  minRows={5} disabled={isLoading}/>
      </FormControl>
      {/* <Button variant="outlined" type='submit'>Send</Button> */}
      <LoadingButton
            variant={"contained"}
            size={"large"}
            loading={isLoading}
            sx={{ textTransform: "none" }}
            type="submit"
          >
            Send
          </LoadingButton>
    </form>
      </React.Fragment>
  );
};

 export default App;